@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

header,
nav,
footer {
    padding: 1rem;
    background-color: #f8f9fa;
    text-align: center;
}

nav ul {
    list-style: none;
    padding: 0;
}

nav ul li {
    display: inline;
    margin: 0 1rem;
}

nav ul li a {
    text-decoration: none;
    color: #007bff;
}

nav ul li a:hover {
    text-decoration: underline;
}

section {
    padding: 2rem;
}

.project-card {
    border: 1px solid #ddd;
    padding: 1rem;
    margin: 1rem 0;
    border-radius: 4px;
}

.project-card h3 {
    margin-top: 0;
}

.project-card a {
    color: #007bff;
}

.project-card a:hover {
    text-decoration: underline;
}

form div {
    margin-bottom: 1rem;
}

form label {
    display: block;
    margin-bottom: 0.5rem;
}

form input,
form textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
}

button {
    padding: 0.5rem 1rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}

/* Navbar.module.scss */
.navbar {
    padding: 1rem;
    background-color: #f8f9fa;
    text-align: center;

    ul {
        list-style: none;
        padding: 0;
    }

    ul li {
        display: inline;
        margin: 0 1rem;
    }

    ul li a {
        text-decoration: none;
        color: #007bff;

        &:hover {
            text-decoration: underline;
        }
    }
}

/* src/pages/About.module.scss */

.about {
    padding: 2rem;
    font-family: Arial, sans-serif;

    h2 {
        font-size: 2.5rem;
        margin-bottom: 1rem;
        color: #333;
    }

    p {
        font-size: 1.1rem;
        line-height: 1.6;
        color: #555;
        margin-bottom: 1rem;
    }

    .highlight {
        font-weight: bold;
        color: #007bff;
    }

    .section {
        margin-bottom: 2rem;
    }

    .interests {
        display: flex;
        flex-direction: column;

        @media (min-width: 600px) {
            flex-direction: row;
            justify-content: space-between;
        }

        div {
            flex: 1;
            margin-bottom: 1rem;

            @media (min-width: 600px) {
                margin-bottom: 0;
                margin-right: 1rem;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}
